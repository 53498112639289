document.addEventListener("DOMContentLoaded", function (event) {

    const accordion = () => {
        const accordions = document.querySelectorAll('.accordion-js');
        accordions.forEach(container => {
            const btns = container.querySelectorAll('.accordion-btn-js');
            btns.forEach(btn => {
                btn.addEventListener('click', e => {
                    const classTargetItem = `.${e.currentTarget.getAttribute('data-target')}`;
                    const targetItem = document.querySelector(classTargetItem);

                    if (!targetItem.classList.contains('is-active')) {
                        targetItem.classList.add('is-active');
                        e.currentTarget.classList.add('active');
                        targetItem.style.height = 'auto';
                        const height = `${targetItem.clientHeight}px`;
                        targetItem.style.height = '0px';
                        setTimeout(() => {
                            targetItem.style.height = height;
                        }, 0);
                    } else {
                        targetItem.style.height = '0px';
                        e.currentTarget.classList.remove('active');
                        targetItem.addEventListener(
                            'transitionend',
                            () => {
                                targetItem.classList.remove('is-active');
                            }, {
                                once: true
                            },
                        );
                    }
                });
            });
        });
    };

    accordion();

});